<template>
  <div class="contact-info">
    <FeedbackInput
      v-for="(item, index) in items"
      :key="index"
      :id="item.id"
      :placeholder="item.text"
      :text="
        data.values.find(it => it.id === item.id)
          ? data.values.find(it => it.id === item.id).value
          : ''
      "
      @updateValue="update()"
    />
  </div>
</template>

<script>
import FeedbackInput from "@/components/feedback/ui/FeedbackInput";
export default {
  name: "Form",
  components: { FeedbackInput },
  data: () => {
    return {
      data: {
        values: []
      }
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    },
    questionId: {
      type: Number,
      default: () => 0
    }
  },
  mounted() {
    for (let i = 0; i < this.items.length; i++) {
      this.data.values.push({ id: this.items[i].id, value: "" });
    }
  },
  methods: {
    getAnswer() {
      let answer = [];
      for (let i = 0; i < this.data.values.length; i++) {
        answer.push({
          questionId: this.questionId,
          optionValue: this.data.values[i].value,
          optionId: this.data.values[i].id
        });
      }
      return answer;
    },
    update(item) {
      this.data.values[
        this.data.values.findIndex(it => it.id === item.id)
      ] = item;
    }
  }
};
</script>

<style scoped></style>
